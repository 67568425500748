import { DependencyList, useCallback, useEffect } from 'react';

/**
 * Important note: The effect will NOT be awaited!
 *
 * This is simply a convenience function for using await inside a useEffect.
 */
export default function useEffectAsync(
    effect: () => Promise<void>,
    deps?: DependencyList
): void {
    const effectMemo = useCallback(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        effect();
    }, [effect]);

    useEffect(effectMemo, deps);
}
